import { useState, useEffect, useRef, Fragment } from 'react'
import mapboxgl from 'mapbox-gl'
import MainMenu from '../MainMenu'
import DesktopMenu from '../DesktopMenu'

mapboxgl.accessToken = 'pk.eyJ1IjoidGVzc3Rpbmc1NjciLCJhIjoiY2twYmJwY2Z0MDV3cDJ5cnRtdTdkcmp2eCJ9.USaJNU9MX-pj5mihAORhMA'

const ROUTE_UNCLASSIFIED = 0
const ROUTE_INSUFFICIENT = 1
const ROUTE_ALMOST = 2
const ROUTE_GOOD = 3
const ROUTE_BRILLIANT = 4

const getColorForRoute = route => {
	switch (route) {
		case ROUTE_BRILLIANT:
			return '#1c8131'
		case ROUTE_GOOD:
			return '#83b200'
		case ROUTE_ALMOST:
			return '#ffaa00'
		case ROUTE_INSUFFICIENT:
			return '#ff565d'
		case ROUTE_UNCLASSIFIED:
			return '#cecbc8'
		default:
			return '#cecbc8'
	}
}

const MapForVote = ({ dataForVote, votedData, isGlobal, onVoteSubmit, classification }) => {
	const [mapBox, setMapBox] = useState(null)
	const votedDataRef = useRef(votedData)
	const mapContainer = useRef()
	// List of IDs of selected lines
	const listOfClickedLines = useRef([])
	// This ref represents the same as selecteData, becausee of reading it inside onClick map function
	const listOfClickedFeatures = useRef([])
	// Track unselectable feature for switching between overlapping edges
	const unselectableFeatureRef = useRef(null)
	const [selecteData, setSelectedData] = useState([])
	const mapImagesRef = useRef({
		arrow: { url: '/assets/arrow.png', isLoaded: false, data: null },
	})

	const getVoteAndColor = feature => {
		// Defaults to unclassified
		let vote = 0
		const ogcFid = feature?.properties?.ogc_fid
		const voteObj = votedDataRef.current[ogcFid]

		if (isGlobal) {
			vote = voteObj?.globalVote ?? 0
		}

		if (classification) {
			vote = voteObj?.[classification] ?? 0
		}

		return { vote, color: getColorForRoute(vote) }
	}

	const getDataForMap = () => {
		const dataForMap = {
			type: 'FeatureCollection',
			features: dataForVote.features.map(feature => {
				const { vote, color } = getVoteAndColor(feature)
				const newFeature = {
					...feature,
					properties: {
						...feature.properties,
						isSelected: false,
						color,
						vote,
					},
				}

				return newFeature
			}),
		}

		return dataForMap
	}

	const onVote = async mark => {
		if (!onVoteSubmit) {
			return
		}

		const { data } = await onVoteSubmit(selecteData, mark)
		if (data) {
			// reset selected data
			setSelectedData([])
			listOfClickedLines.current = []
			listOfClickedFeatures.current = []
		}
	}

	useEffect(() => {
		votedDataRef.current = votedData
		if (mapBox) {
			mapBox.getSource('places').setData(getDataForMap())
		}
	}, [votedData])

	useEffect(() => {
		if (dataForVote?.type && !mapBox) {
			const lat = dataForVote?.features[0]?.geometry?.coordinates[0][1]
			const lon = dataForVote?.features[0]?.geometry?.coordinates[0][0]

			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/djra/ckx90pkh58k5d15p5066x6x7r',
				center: [lon, lat],
				zoom: 14,
				minZoom: 9,
			})

			// Add geolocate control to the map.
			map.addControl(
				new mapboxgl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
					// When active the map will receive updates to the device's location as it changes.
					trackUserLocation: true,
					// Draw an arrow next to the location dot to indicate which direction the device is heading.
					showUserHeading: true,
				}),
				'top-right',
			)

			map.on('load', () => {
				map.addSource('places', { type: 'geojson', data: getDataForMap() })

				map.addLayer({
					id: 'places',
					type: 'line',
					source: 'places',
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
						// Render selected features on top, and then rated features (unclassified vote = 0 => last)
						'line-sort-key': ['case', ['get', 'isSelected'], 100, ['get', 'vote']],
					},
					paint: {
						'line-color': ['case', ['get', 'isSelected'], '#3CADE8', ['get', 'color']],
						'line-width': 7,
					},
				}).addLayer({
					id: 'places-arrow',
					type: 'symbol',
					source: 'places',
					layout: {
						'icon-image': 'arrow',
						'icon-rotation-alignment': 'map',
						'symbol-placement': 'line-center',
					},
					// Display arrow icon only for selected edges
					filter: ['get', 'isSelected'],
				})

				map.on('click', 'places', e => {
					let listOfLines = listOfClickedLines.current
					let listOfFeatures = listOfClickedFeatures.current
					// Inside this function we can only read correct data from refs not from state vars
					let newSelectedData = [...listOfFeatures]

					let clickedFeature = e.features[0]
					// TODO: Use reversed_fid property when available with directional network update
					let overlappingFeature = e.features.find(
						feature =>
							JSON.stringify([...feature.geometry.coordinates].reverse()) ===
							JSON.stringify(clickedFeature.geometry.coordinates),
					)

					if (listOfLines.includes(clickedFeature.id) && overlappingFeature) {
						// If overlapping feature is present, prefer selecting it as clicked to be able to
						// switch between clicked overlapping feature
						const tempFeature = clickedFeature
						clickedFeature = overlappingFeature
						overlappingFeature = tempFeature
					}

					let selectedFeature, removedFeature
					if (listOfLines.includes(clickedFeature.id)) {
						// Clicked feature has already been selected
						removedFeature = clickedFeature
					} else {
						if (unselectableFeatureRef.current !== clickedFeature.id) {
							// Select clicked feature if it is not set as unselectable
							selectedFeature = clickedFeature
						}
						// Clear unselectable feature
						unselectableFeatureRef.current = null

						if (overlappingFeature && listOfLines.includes(overlappingFeature.id)) {
							// If overlapping feature has been selected, remove it from selected features
							// and mark it as unselectable, so that selected overlapping edge is not switched
							// indefinitely but cleared at the third consecutive click
							removedFeature = overlappingFeature
							unselectableFeatureRef.current = overlappingFeature.id
						}
					}

					if (selectedFeature) {
						listOfLines.push(selectedFeature.id)
						listOfFeatures.push(selectedFeature)
						newSelectedData.push(selectedFeature)
					}

					if (removedFeature) {
						listOfLines = listOfLines.filter(id => id !== removedFeature.id)
						listOfFeatures = listOfFeatures.filter(feature => feature.id !== removedFeature.id)
						newSelectedData = newSelectedData.filter(feature => feature.id !== removedFeature.id)
					}

					listOfClickedLines.current = listOfLines
					listOfClickedFeatures.current = listOfFeatures
					setSelectedData(newSelectedData)

					// Update isSelected property for layout ordering and color
					const data = getDataForMap()
					data.features.forEach(
						feature =>
							(feature.properties.isSelected = !!newSelectedData.find(
								selectedFeature => selectedFeature.properties.ogc_fid === feature.properties.ogc_fid,
							)),
					)
					map.getSource('places').setData(data)
				})

				// Change the cursor to a pointer when the mouse is over the places layer.
				map.on('mouseenter', 'places', () => {
					map.getCanvas().style.cursor = 'pointer'
				})

				// Change it back to a pointer when it leaves.
				map.on('mouseleave', 'places', () => {
					map.getCanvas().style.cursor = ''
				})

				map.on('styledata', () => {
					// Add images that can be used as icons in map
					// Use ref to prevent loading images multiple times between renders
					Object.entries(mapImagesRef.current).forEach(([imageKey, imageValue]) => {
						if (!imageValue.isLoaded) {
							mapImagesRef.current[imageKey].isLoaded = true
							// Load image if it has not been loaded yet
							map.loadImage(imageValue.url, (error, result) => {
								if (error) {
									throw error
								}
								// Update ref data
								mapImagesRef.current[imageKey].data = result
								if (!map.hasImage(imageKey) && result) {
									map.addImage(imageKey, result)
								}
							})
						} else if (imageValue.data && !map.hasImage(imageKey)) {
							// Image already loaded, add if it doesn't exist yet
							map.addImage(imageKey, imageValue.data)
						}
					})
				})
			})

			setMapBox(map)

			// cleanup function to remove map on unmount
			return () => map.remove()
		}
	}, [dataForVote])

	const onBack = isGlobal ? '../general-rating' : '../classification-rating'

	return (
		<Fragment>
			<DesktopMenu onBack={onBack} data={{ features: selecteData }} onNewVote={onVote} />
			<div ref={mapContainer} style={{ width: '100vw', height: '100vh' }} />
			<MainMenu
				active={{ title: 'classify' }}
				setGeoLocateStyle={() => null}
				data={{ features: selecteData }}
				simpleVote
				onNewVote={onVote}
			/>
		</Fragment>
	)
}

export default MapForVote
